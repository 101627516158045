import { Component } from '@angular/core';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, filter, map } from 'rxjs';
import { AuthService } from '../shared/services/auth.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent {
  isNavigating$!: Observable<boolean>;
  isUserLoggedIn$!: Observable<boolean>;

  constructor(
    private router: Router,
    private authSerivce: AuthService
  ) {}

  ngOnInit(): void {
    this.isNavigating$ = this.router.events
    .pipe(
      filter(
        (event) =>
          event instanceof NavigationStart ||
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event instanceof NavigationError
      ),
      map((event) => {
        return event instanceof NavigationStart;
      })
    );
    
    this.isUserLoggedIn$ = this.authSerivce.isUserLoggedIn$;
  }
}
