import { Component } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  isUserLoggedIn: boolean = false;
  loggedInUser: string = '';

  constructor(
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.authService.isUserLoggedIn$.subscribe(value => {
      this.isUserLoggedIn = value;

      if(value) {
        this.loggedInUser = this.authService.getTokenData('name');
      }
    });
  }

  onLogout() {
    this.authService.logout();
  }
}
