import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BackendService } from '../shared/services/backend.service';
import { AuthService } from '../shared/services/auth.service';
import { AppliedTimeOff, ApplyTimeOffRequest, TimeOffAudit, TimeOffSummary } from '../shared/models/time-off.model';
import { TimeOffGroup } from '../shared/models/static-data.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CancelConfirmationComponent } from './cancel-confirmation/cancel-confirmation.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApplyTimeOffComponent } from './apply/apply.component';
import { TimeOffInfoComponent } from '../shared/components/time-off-info/time-off-info.component';

@Injectable({
  providedIn: 'root'
})
export class TimeOffService {
  overviewLoading$ = new BehaviorSubject<boolean>(true);
  overviewDetails$ = new BehaviorSubject<TimeOffSummary>({} as TimeOffSummary);

  auditLoading$ = new BehaviorSubject<boolean>(true);
  auditDetails$ = new BehaviorSubject<TimeOffAudit[]>([]);

  pendingForReviewLoading$ = new BehaviorSubject<boolean>(true);
  pendingForReviewDetails$ = new BehaviorSubject<AppliedTimeOff[]>([]);

  historyLoading$ = new BehaviorSubject<boolean>(true);
  historyDetails$ = new BehaviorSubject<AppliedTimeOff[]>([]);

  employeeEmail: string | undefined = '';

  applicableLeavesDetails$ = new BehaviorSubject<any>({});

  constructor(
    private backendService: BackendService,
    private authService: AuthService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  getTimeOffOverview() {
    this.overviewLoading$.next(true);

    const email = this.getEmail();

    this.backendService.getTimeOffOverview(email).subscribe({
      next: (response: TimeOffSummary) => {
        this.overviewLoading$.next(false);
        this.overviewDetails$.next(response);
      }
    });
  }

  getTimeOffAudit() {
    this.auditLoading$.next(true);

    const email = this.getEmail();

    this.backendService.getTimeOffAudit(email).subscribe({
      next: (response: TimeOffAudit[]) => {
        this.auditLoading$.next(false);
        this.auditDetails$.next(response);
      }
    });
  }

  getTimeOffList() {
    this.pendingForReviewLoading$.next(true);

    const email = this.getEmail();

    this.backendService.getTimeOffList(email).subscribe({
      next: (response: AppliedTimeOff[]) => {
        this.pendingForReviewLoading$.next(false);
        this.pendingForReviewDetails$.next(response);
      }
    });
  }

  openCancelConfirmationDialogBox(timeOffId: string) {
    return this.dialog.open(CancelConfirmationComponent, {
      data: {
        timeOffId
      }
    });
  }

  openApplyTimeOffDialogBox() {
    this.dialog.open(ApplyTimeOffComponent, {
      width: '1000px'
    });
  }

  openTimeOffDetailsDialogBox(timeOff: AppliedTimeOff, mode: string) {
    this.dialog.open(TimeOffInfoComponent, {
      width: '1000px',
      data: {
        mode: mode,
        timeOff
      }
    });
  }

  openTimeOffAuditDialogBox(timeOff: AppliedTimeOff) {
    this.dialog.open(TimeOffInfoComponent, {
      width: '1000px',
      data: {
        mode: 'AUDIT',
        timeOff
      }
    });
  }

  applyTimeOff(body: ApplyTimeOffRequest) {
    const email = this.getEmail();

    return this.backendService.applyTimeOff(email, body);
  }
  
  cancelTimeOff(timeOffId: string, dialogRef: MatDialogRef<CancelConfirmationComponent>) {
    const email = this.getEmail();

    this.backendService.cancelTimeOff(timeOffId, email).subscribe({
      next: (response: string) => {
        dialogRef.close();

        this.snackBar.open(response, '✖', {
          duration: 5000,
          panelClass: ['green-snackbar'],
        });

        this.loadAllData();
      }
    });
  }

  getHistory() {
    this.historyLoading$.next(true);

    const email = this.getEmail();

    this.backendService.getTimeOffHistory(email).subscribe({
      next: (response: AppliedTimeOff[]) => {
        this.historyLoading$.next(false);
        this.historyDetails$.next(response);
      }
    })
  }

  loadAllData() {
    this.getHistory();
    this.getTimeOffList();
    this.getTimeOffOverview();
  }

  getEmail() {
    return this.employeeEmail === null || this.employeeEmail === undefined || this.employeeEmail === '' 
        ? this.authService.getUserSessionData('EMAIL') 
        : this.employeeEmail;
  }

  getTagDisplayName(tag: string) {
    const timeOffTypes = this.authService.getStaticData('TIMEOFF_TYPES');
    let friendlyName = '';
    
    (timeOffTypes).forEach((group: TimeOffGroup) => {
      if(group.tag === tag) {
        friendlyName = group.friendly_name;
      }
    });

    return friendlyName;
  }

  getTimeOffCount(type: string) {
    let allAvailableLeaves = Object.entries(this.overviewDetails$.value);
    let currentAvailableLeave = 0;

    for(let i = 0; i < allAvailableLeaves.length; i++) {
      if(allAvailableLeaves[i][0] === type) {
        currentAvailableLeave = allAvailableLeaves[i][1];
      }
    }

    return currentAvailableLeave;
  }

  approveTimeOff(timeOff: AppliedTimeOff, comments: any) {
    return this.backendService.putTimeOffAction(timeOff.MEMBER_ID, timeOff.TIMEOFF_ID, 'APPROVED', comments);
  }

  rejectTimeOff(timeOff: AppliedTimeOff, comments: any) {
    return this.backendService.putTimeOffAction(timeOff.MEMBER_ID, timeOff.TIMEOFF_ID, 'REJECTED', comments);
  }
}
