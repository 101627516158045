import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import { BackendService } from '../services/backend.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401 ) {
                // auto logout if 401 response returned from api
                this.authService.logout();
                return throwError(() => err);

            } else if (err.status === 403) {
                // auto logout if 403 response returned from api
                this.authService.logout();
                return throwError(() => err);

            } else {
              return throwError(() => err);
            }
        }));
    }
}
