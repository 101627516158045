import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import {
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { AuthService } from '../shared/services/auth.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  loginForm = new FormGroup({
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required)
  });
  loginFormSubmitted = false;
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  resetPasswordUrl: string = environment.RESET_PASSWORD;
  private readonly _destroying$ = new Subject<null>();
  loginStatus = false;
  loginLoader!: string | null;

  constructor(
    private authService: AuthService,
    private router: Router,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
  ) {};

  ngOnInit() {

    if(!sessionStorage.getItem('isAuthLoading')){
      sessionStorage.setItem('isAuthLoading', "false");
    }
    this.loginLoader = sessionStorage.getItem("isAuthLoading");

    if(this.authService.isUserLoggedIn$.getValue()) {
      this.router.navigate(['/home']);
    }

    this.msalService.handleRedirectObservable().subscribe({
      next: (result) => {
        if (!result) {
          this.loginLoader = "false";
          sessionStorage.setItem('isAuthLoading', "false");
        }
      },
      error: () => {
        this.loginLoader = "false";
        sessionStorage.setItem('isAuthLoading', "false");
      }
    });

    this.msalBroadcastService.msalSubject$
    .pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
    )
    .subscribe((result: EventMessage) => {
      if (this.msalService.instance.getAllAccounts().length === 0) {
        this.router.navigate(['/login']);
      } else {
        this.setLoginDisplay();
      }
    });

    this.msalBroadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$)
    )
    .subscribe(() => {
      this.setLoginDisplay();
    })
  }

  onSubmit(){
    if(this.loginForm.valid) {
      this.loginFormSubmitted = true;
      this.authService.login({
        username: this.loginForm.value['username']!,
        password: this.loginForm.value['password']!
      })
      .subscribe({
        next: () => {
          this.authService.isUserLoggedIn$.next(true);
          this.router.navigate(['/home'])
        },
        error: () => {
          this.loginFormSubmitted = false;
        }
      });
      this.router.navigate(['/home']);
    }
  }

  onSsoLogin() {
    this.loginLoader = "true";
    sessionStorage.setItem('isAuthLoading', "true");
    this.authService.loginSso();
  }

  setLoginDisplay() {
    this.loginStatus = this.msalService.instance.getAllAccounts().length > 0;

    if(this.loginStatus){
      const profile_data = this.msalService.instance.getAllAccounts()[0];
      this.msalService.instance.setActiveAccount(profile_data);
      this.setMsalToken();
    } else {
      this.router.navigate(['/login']);
    }
  }

  setMsalToken() {
    const tokenRequest = {
        scopes: ["user.read"],
        account: this.msalService.instance.getAllAccounts()[0]
    }

    this.msalService.acquireTokenSilent(tokenRequest).subscribe((tokenResponse) => {
        if (tokenRequest != null) {
            sessionStorage.setItem("msal-access-token", tokenResponse.accessToken);
            sessionStorage.setItem("msal-id-token", tokenResponse.idToken);

            this.authService.loginUsingSso({
              accessToken: sessionStorage.getItem('msal-access-token')!,
              idToken: sessionStorage.getItem('msal-id-token')!,
              email: this.msalService.instance.getActiveAccount()?.username!
            }).subscribe({
              next: () => {
                this.authService.isUserLoggedIn$.next(true);
                this.loginLoader = "false";
                sessionStorage.setItem('isAuthLoading', "false");
                this.router.navigate(['/home']);
              },
              error: () => {
                this.loginLoader = "false";
                sessionStorage.setItem('isAuthLoading', "false");
              }
            });
        }
    })
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

}
