import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "../services/auth.service";
import { Observable } from "rxjs";

import { InterceptorSkip } from '../services/auth.service';
import { environment } from "src/environments/environment";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  
  constructor(
    private authService: AuthService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.headers && request.headers.has(InterceptorSkip)) {
      const headers = request.headers.delete(InterceptorSkip);
      request = request.clone({ headers });
    } else {
      const idtoken = this.authService.getToken();
      request = request.clone({
        headers: request.headers.set('Authorization', String(idtoken))
      });
    }

    const localEnvironment = window.location.href.indexOf('.com') === -1;
    const serverlessApi = this.isServerlessApi(request.url);

    if (localEnvironment && serverlessApi) {
      request = this.applyCorsPolicy(request);
    }
    return next.handle(request);
  }

  private isServerlessApi(url: string): boolean {
    if (!url) {
      return false;
    }
    if (/api./.test(url)) {
      return true;
    }
    return false;
  }

  private applyCorsPolicy(req: HttpRequest<any>): HttpRequest<any> {
    if (/api/.test(req.url)) {
      return req.clone({
        url: req.url.replace(environment.BASE_URL, ''),
      });
    }
    return req;
  }
}
