import { Component } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {
  screens!: string[];
  isMobileView = false;

  constructor(
    private authService: AuthService,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit() {
    this.screens = this.authService.getStaticData('SCREEN_PERMS');

    this.breakpointObserver.observe([Breakpoints.Handset, '(max-width: 768px)']).subscribe(result => {
      this.isMobileView = result.matches;
    });
  }
}
