import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TimeOffService } from '../time-off.service';

@Component({
  selector: 'app-cancel-confirmation',
  templateUrl: './cancel-confirmation.component.html',
  styleUrls: ['./cancel-confirmation.component.scss']
})
export class CancelConfirmationComponent {
  loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private timeOffService: TimeOffService,
    private dialogRef: MatDialogRef<CancelConfirmationComponent>
  ) {}

  cancelTimeOff() {
    this.loading = true;

    this.timeOffService.cancelTimeOff(this.data.timeOffId, this.dialogRef);
  }
}
