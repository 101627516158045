import { Directive, ElementRef, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { AuthService } from "../services/auth.service";

@Directive({
  selector: '[appCheckPermissions]',
})
export class CheckPermissionsDirective {
  private hasView = false;

  constructor(
    private authService: AuthService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {}
    
  @Input() set appCheckPermissions(featureName: string) {
    if (this.authService.checkPermission(featureName ?? '')) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.hasView = true;
    } else {
        this.viewContainer.clear();
        this.hasView = false;
      }
  }
}