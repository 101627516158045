<mat-toolbar color="accent" class="toolbar">
    <nav>
        <ng-container *ngFor="let screen of screens">
            <a 
                *ngIf="!(isMobileView && screen.toLowerCase() === 'events')" 
                mat-button
                [routerLink]="[screen | lowercase]"
                [routerLinkActive]="'nav-active'">
                {{ screen }}
            </a>
        </ng-container>
    </nav>
</mat-toolbar>