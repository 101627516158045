<form
    [formGroup]="timeOffActionsForm"
>
    <div mat-dialog-title class="header">
        <h3>
            <span *ngIf="data.mode === 'VIEW'">Cancel Time Off</span>
            <span *ngIf="data.mode === 'APPROVALS'">Approve Time Off</span>
            <span *ngIf="data.mode === 'AUDIT'">Time Off Details</span>
        </h3>
        <mat-icon mat-dialog-close>close</mat-icon>
    </div>

    <mat-dialog-content>
        <div class="time-off-form">
            <div class="left-content">
                <mat-form-field appearance="outline" class="input-full-width">
                    <mat-label>Requested By</mat-label>
                    <input matInput disabled="true" [value]="data.timeOff.NAME">
                </mat-form-field>

                <mat-form-field appearance="outline" class="input-full-width">
                    <mat-label>Approver</mat-label>
                    <input matInput disabled="true" [value]="data.timeOff.TIMEOFF_APPROVER">
                </mat-form-field>

                <mat-form-field appearance="outline" class="input-full-width">
                    <mat-label>Time-Off Type</mat-label>
                    <input matInput disabled="true" [value]="data.timeOff.TIMEOFF_TYPE">
                </mat-form-field>

                <mat-form-field appearance="outline" class="input-full-width">
                    <mat-label>Reason</mat-label>
                    <input matInput disabled="true" [value]="data.timeOff.REASON">
                </mat-form-field>
            </div>

            <div class="right-content">
                <mat-form-field appearance="outline" class="input-full-width">
                    <mat-label>Date range</mat-label>
                    <input matInput disabled="true" value="{{data.timeOff.FROM_DATE}} - {{data.timeOff.TO_DATE}}">
                </mat-form-field>
                <div *ngFor="let timeoffDate of data.timeOff.TIMEOFF_DATES;" class="col">
                    <mat-form-field appearance="outline" class="input-full-width">
                        <mat-label>{{ timeoffDate.DATE }}</mat-label>
                        <input matInput disabled="true" *ngIf="timeoffDate.QTY === '1'" value="Full Day (1)">
                        <input matInput disabled="true" *ngIf="timeoffDate.QTY === '0.5'" value="Half Day (0.5)">
                        <input matInput disabled="true" *ngIf="timeoffDate.QTY === 'WEEKEND'" value="WEEKEND">
                        <input matInput disabled="true" *ngIf="timeoffDate.QTY === 'HOLIDAY'" value="HOLIDAY">
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div *ngIf="data.mode === 'APPROVALS'">
            <mat-form-field appearance="outline" class="input-full-width">
                <mat-label>Comments</mat-label>
                <textarea matInput formControlName="comments" maxlength="200"></textarea>
                <mat-error
                    *ngIf="timeOffActionsForm.invalid && (timeOffActionsForm.dirty || timeOffActionsForm.touched)">
                    Comments is required
                </mat-error>
            </mat-form-field>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button
            *ngIf="data.mode === 'VIEW' && showCancelButton"
            type="button"
            mat-raised-button
            color="warn"
            class="time-off-action-button"
            [disabled]="isSubmitted"
            (click)="cancelTimeOff()"
            >
            <span *ngIf="isSubmitted"><mat-spinner diameter="20"></mat-spinner></span>
            <span *ngIf="!isSubmitted">Cancel Time Off</span>
        </button>
        <button
            *ngIf="data.mode === 'APPROVALS' && showApproveButton"
            type="button"
            mat-raised-button
            color="primary"
            class="time-off-action-button"
            [disabled]="isApproved"
            (click)="approveTimeOff(data.timeOff)"
            >
            <span *ngIf="isApproved"><mat-spinner diameter="20"></mat-spinner></span>
            <span *ngIf="!isApproved">Approve Time-Off</span>
        </button>
        <button
            *ngIf="data.mode === 'APPROVALS' && showRejectButton"
            type="button"
            mat-raised-button
            color="warn"
            class="time-off-action-button"
            [disabled]="isRejected"
            (click)="rejectTimeOff(data.timeOff)"
            >
            <span *ngIf="isRejected"><mat-spinner diameter="20"></mat-spinner></span>
            <span *ngIf="!isRejected">Reject Time-Off</span>
        </button>
        <button mat-stroked-button mat-dialog-close>Close</button>
    </mat-dialog-actions>
</form>
