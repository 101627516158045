import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppliedTimeOff } from '../../models/time-off.model';
import { TimeOffService } from 'src/app/time-off/time-off.service';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TimeOffApprovalsService } from 'src/app/time-off-approvals/time-off-approvals.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-time-off-info',
  templateUrl: './time-off-info.component.html',
  styleUrls: ['./time-off-info.component.scss']
})
export class TimeOffInfoComponent {
  isSubmitted = false;
  isApproved = false;
  isRejected = false;
  timeOffActionsForm = new FormGroup({
    comments: new FormControl('')
  });

  showCancelButton = false;
  showApproveButton = false;
  showRejectButton = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TimeOffDetailDialogData,
    private timeOffService: TimeOffService,
    private timeOffApprovalsService: TimeOffApprovalsService,
    private dialogRef: MatDialogRef<TimeOffInfoComponent>,
    private snackBar: MatSnackBar,
    private authService: AuthService
  ) {}

  ngOnInit() {
    if (this.data.mode === 'VIEW') {
      this.showCancelButton = this.hasPermission('timeoff_cancel');
    }
    else if (this.data.mode === 'APPROVALS') {
      this.showApproveButton = this.hasPermission('timeoff_approve');
      this.showRejectButton = this.hasPermission('timeoff_reject');
    }
  }

  cancelTimeOff() {
    let cancelConfirmatinDialogRef = this.timeOffService.openCancelConfirmationDialogBox(this.data.timeOff.TIMEOFF_ID);

    cancelConfirmatinDialogRef.afterClosed().subscribe({
      next: () => this.dialogRef.close()
    })
  }

  approveTimeOff(timeOff: AppliedTimeOff) {
    this.isApproved = true;
    this.isRejected = false;

    if(!this.timeOffActionsForm.valid) {
      return;
    }

    const comments = this.timeOffActionsForm.value['comments'];

    this.timeOffService.approveTimeOff(timeOff, comments).subscribe({
      next: (message: string) => {
        this.snackBar.open(message,  '✖', {
          duration: 5000,
          panelClass: ['green-snackbar'],
        });

        this.dialogRef.close();
        if(this.data.mode === 'APPROVALS') {
          this.timeOffApprovalsService.loadApprovalsData();
        }
        else {
          this.timeOffService.loadAllData();
        }
      },
      error: () => {
        this.isApproved = false;
      }
    });
  }

  rejectTimeOff(timeOff: AppliedTimeOff) {
    this.isRejected = true;
    this.isApproved = false;

    if(!this.timeOffActionsForm.valid || 
      this.timeOffActionsForm.controls['comments'].value === undefined || 
      this.timeOffActionsForm.controls['comments'].value === null || 
      this.timeOffActionsForm.controls['comments'].value === '') {
        this.isRejected = false;
        this.snackBar.open('Comments is required.',  '✖', {
          duration: 5000,
          panelClass: ['red-snackbar'],
        });
        return;
    }

    const comments = this.timeOffActionsForm.value['comments'];

    this.timeOffService.rejectTimeOff(timeOff, comments).subscribe({
      next: (message: string) => {
        this.snackBar.open(message,  '✖', {
          duration: 5000,
          panelClass: ['green-snackbar'],
        });

        this.dialogRef.close();
        if(this.data.mode === 'APPROVALS') {
          this.timeOffApprovalsService.loadApprovalsData();
        }
        else {
          this.timeOffService.loadAllData();
        }
      },
      error: () => {
        this.isRejected = false;
      }
    });
  }

  hasPermission(perm: string) {
    let loggedInUserPermissions = this.authService.getStaticData('UI_PERMS');

    return loggedInUserPermissions.includes(perm);
  }
}

interface TimeOffDetailDialogData {
  mode: string,
  timeOff: AppliedTimeOff
}
