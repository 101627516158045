<h2 mat-dialog-title>Cancel Confirmation</h2>
<mat-dialog-content class="mat-typography">
    <p>Are you sure you want to cancel your time-off?</p>
</mat-dialog-content>
<mat-dialog-actions>
    <button 
        mat-flat-button 
        color="warn" 
        cdkFocusInitial
        class="delete-button"
        [disabled]="loading"
        (click)="cancelTimeOff()">
        <span *ngIf="!loading">Yes, I am sure</span>
        <span *ngIf="loading">
            <mat-spinner [diameter]="20"></mat-spinner>
        </span>
    </button>

    <button 
        mat-button 
        mat-dialog-close
        [disabled]="loading"
        >Close</button>
</mat-dialog-actions>