import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

import { catchError, concatMap, map, throwError } from "rxjs";

import { SummaryResponse } from "../models/summary.model";
import { LoginRequest, LoginSsoRequest } from "../models/external/login-request.model";
import { LoginResponse } from "../models/external/login-response.model";
import { MembersList } from "../models/members-list.model";
import { StaticSessionStorageKey, TokenSessionStorageKey, UserSessionStorageKey } from "./auth.service";
import { environment } from "../../../environments/environment";
import { StaticData } from "../models/static-data.model";
import { MemberDetails } from "../models/member-details.model";
import { MemberProfile } from "../models/member-profile.model";
import {
    AppliedTimeOff,
    ApplyTimeOffRequest,
    ApprovalResponse,
    TimeOffAudit,
    TimeOffSummary
} from "../models/time-off.model";
import { Events } from "../models/events-details.model";
import { AddScore, EventScores } from "../models/event-scores.model";
import { AddActivity, AddEvent, AddTeam } from "../models/events-details.model";

@Injectable({ providedIn: 'root' })
export class BackendService {
    InterceptorSkipHeader = new HttpHeaders({
        'X-Skip-Interceptor': ''
    });

    constructor(
        private http: HttpClient,
        private snackBar: MatSnackBar
    ) { }

    login(loginData: LoginRequest) {
        const url = this.createUrl(environment.LOGIN_ENDPOINT);

        return this.http
            .post<LoginResponse>(
                url,
                loginData,
                { headers: this.InterceptorSkipHeader })
            .pipe(
                concatMap((response: LoginResponse) => {
                    sessionStorage.setItem(TokenSessionStorageKey, response.idtoken);
                    sessionStorage.setItem(UserSessionStorageKey, JSON.stringify(response.user));

                    let staticUrl = this.createUrl(environment.STATIC);

                    return this.http.get<StaticData>(
                        staticUrl
                    )
                        .pipe(
                            map((staticResponse: any) => {
                                sessionStorage.setItem(StaticSessionStorageKey, JSON.stringify(staticResponse.body));
                                return response;
                            })
                        );
                }),
                catchError((error: HttpErrorResponse) => this.handleError(error))
            );
    }

    loginUsingSso(loginData: LoginSsoRequest) {
        const url = this.createUrl(environment.LOGIN_SSO_ENDPOINT);

        return this.http
            .post<any>(
                url,
                loginData,
                { headers: this.InterceptorSkipHeader })
            .pipe(
                concatMap((response) => {
                    sessionStorage.setItem(TokenSessionStorageKey, response.idtoken);
                    sessionStorage.setItem(UserSessionStorageKey, JSON.stringify(response.user));

                    let staticUrl = this.createUrl(environment.STATIC);

                    return this.http.get<StaticData>(
                        staticUrl
                    )
                        .pipe(
                            map((staticResponse: any) => {
                                sessionStorage.setItem(StaticSessionStorageKey, JSON.stringify(staticResponse.body));
                                return response;
                            })
                        );
                }),
                catchError((error: HttpErrorResponse) => this.handleError(error))
            );
    }

    getOrganizationSummary() {
        const url = this.createUrl(environment.EMPLOYEES.ORGANIZATION_SUMMARY_ENDPOINT);

        return this.http.get<SummaryResponse>(url)
            .pipe(
                catchError((error: HttpErrorResponse) => this.handleError(error))
            );
    }

    getOrganizationMembers() {
        const url = this.createUrl(environment.EMPLOYEES.MEMBER_LIST_ENDPOINT);

        return this.http.get<MembersList>(url)
            .pipe(
                catchError((error: HttpErrorResponse) => this.handleError(error))
            );
    }

    addMember(member: any) {
        const url = this.createUrl(environment.EMPLOYEES.ADD_MEMBER_ENDPOINT);

        return this.http.post(
            url,
            member
        )
            .pipe(
                catchError((error: HttpErrorResponse) => this.handleError(error))
            );
    }

    updateMember(email: string, member: any) {
        const url = this.createUrl(environment.EMPLOYEES.UPDATE_MEMBER_ENDPOINT, email);

        return this.http.put(
            url,
            member
        )
            .pipe(
                catchError((error: HttpErrorResponse) => this.handleError(error))
            );
    }

    deleteMember(email: string) {
        const url = this.createUrl(environment.EMPLOYEES.DELETE_MEMBER_ENDPOINT, email);

        return this.http.delete(url)
            .pipe(
                catchError((error: HttpErrorResponse) => this.handleError(error))
            );
    }

    getUserDetails(email: string) {
        const url = this.createUrl(environment.USER_DETAILS, email);

        return this.http.get<MemberDetails>(url)
            .pipe(
                catchError((error: HttpErrorResponse) => this.handleError(error)),
                map((response: any) => response.body)
            );
    }

    getUserProfile(email: string) {
        const url = this.createUrl(environment.USER_PROFILE, email);

        return this.http.get<MemberProfile>(url)
            .pipe(
                catchError((error: HttpErrorResponse) => this.handleError(error)),
                map((response: any) => response.body)
            );
    }

    getTimeOffOverview(email: string) {
        const url = this.createUrl(environment.TIME_OFF.SUMMARY, email);

        return this.http.get<TimeOffSummary>(url)
            .pipe(
                catchError((error: HttpErrorResponse) => this.handleError(error)),
                map((response: any) => response.body)
            );
    }

    getTimeOffAudit(email: string) {
        const url = this.createUrl(environment.TIME_OFF.AUDIT, email);

        return this.http.get<TimeOffAudit[]>(url)
            .pipe(
                catchError((error: HttpErrorResponse) => this.handleError(error)),
                map((response: any) => response.body)
            );
    }

    getTimeOffList(email: string) {
        const url = this.createUrl(environment.TIME_OFF.MEMBER_PENDING_APPROVAL, email);

        return this.http.get<ApprovalResponse>(url)
            .pipe(
                catchError((error: HttpErrorResponse) => this.handleError(error)),
                map((response: any) => response.body)
            );
    }

    applyTimeOff(email: string, body: ApplyTimeOffRequest) {
        const url = this.createUrl(environment.TIME_OFF.APPLY_TIME_OFF, email);

        return this.http.post<string>(
            url,
            body
        )
            .pipe(
                map((response: any) => response.message)
            );
    }

    cancelTimeOff(timeOffId: string, email: string) {
        let url = this.createUrl(environment.TIME_OFF.CANCEL, email);
        url = url.replace('{timeOffId}', timeOffId);

        return this.http.delete<string>(url)
            .pipe(
                catchError((error: HttpErrorResponse) => this.handleError(error)),
                map((response: any) => response.message)
            );
    }

    getTimeOffHistory(email: string) {
        const url = this.createUrl(environment.TIME_OFF.HISTORY, email);

        return this.http.get<AppliedTimeOff[]>(url)
            .pipe(
                catchError((error: HttpErrorResponse) => this.handleError(error)),
                map((response: any) => response.body)
            );
    }

    uploadAttendance(uploadContent: any) {
        const url = this.createUrl(environment.ATTENDANCE.UPLOAD)
        return this.http.post(
            url,
            uploadContent
        )
            .pipe(
                catchError((error: HttpErrorResponse) => this.handleError(error))
            );
    }

    getMonthAttendance(year: string, month: string) {
        let url = this.createUrl(environment.ATTENDANCE.MONTHLY);
        url = url.replace('{year}', year).replace('{month}', month);

        return this.http.get(url)
            .pipe(
                catchError((error: HttpErrorResponse) => this.handleError(error)),
                map((response: any) => response.body)
            );
    }

    getNextRecords(year: string, month: string, snode: string, stype: string){
        let url = this.createUrl(environment.ATTENDANCE.MONTHLY);
        url = url.replace('{year}', year).replace('{month}', month);

        let params = new HttpParams()
          .set('snode', snode)
          .set('stype', stype);

        return this.http.get(url, { params: params })
          .pipe(
            catchError((error: HttpErrorResponse) => this.handleError(error)),
            map((response: any) => response.body)
          );
      }

    private createUrl(url: string, email = '') {
        return environment.BASE_URL + url.replace('{email}', email);
    }

    private handleError(error: HttpErrorResponse) {
        if (error.status == 400) {
            this.snackBar.open( error.error['message'], '✖', {
                duration: 5000,
                panelClass: ['red-snackbar'],
            });
        }
        else if (error.status == 401 || error.status == 403) {
            this.snackBar.open('Unauthorized. You are not allowed for this action.', '✖', {
                duration: 5000,
                panelClass: ['red-snackbar'],
            });
        }
        else {
            this.snackBar.open('Something went wrong. Please contact the admin.', '✖', {
                duration: 5000,
                panelClass: ['red-snackbar'],
            });
        }

        return throwError(() => error);
    }

    getPendingTimeOffApprovalsList(email: string) {
        const url = this.createUrl(environment.APPROVALS.MANAGER_PENDING_APPROVAL, email);

        return this.http.get<ApprovalResponse>(url)
            .pipe(
                catchError((error: HttpErrorResponse) => this.handleError(error)),
                map((response: any) => response.body)
            );
    }

    getPendingTimeOffApprovalsManagerList() {
        const url = this.createUrl(environment.APPROVALS.MANAGER_PENDING_APPROVAL_LIST);

        return this.http.get<ApprovalResponse>(url)
            .pipe(
                catchError((error: HttpErrorResponse) => this.handleError(error)),
                map((response: any) => response.body)
            );
    }

    getApprovalsHistory(email: string) {
        const url = this.createUrl(environment.APPROVALS.HISTORY, email);

        return this.http.get<AppliedTimeOff[]>(url)
            .pipe(
                catchError((error: HttpErrorResponse) => this.handleError(error)),
                map((response: any) => response.body)
            );
    }

    putTimeOffAction(memberEmail: string, timeOffId: string, status: string, comments: string) {
        let url = this.createUrl(environment.APPROVALS.ACTION, memberEmail);
        url = url.replace('{timeOffId}', timeOffId);

        const body = {
            "STATUS": status,
            "COMMENTS": comments
        };

        return this.http.put<string>(
            url,
            body
        )
            .pipe(
                catchError((error: HttpErrorResponse) => this.handleError(error)),
                map((response: any) => response.message)
            );
    }

    putCreditTimeOffAction(memberEmail: string, timeOffType: string, timeOffQuantity: number, reason: string) {
        let url = this.createUrl(environment.TIME_OFF.CREDIT, memberEmail);
        const body = {
            "TIMEOFF_TYPE": timeOffType,
            "TIMEOFF_CREDIT": timeOffQuantity,
            "REASON": reason
        }
        return this.http.put<string>(
            url,
            body
        ).pipe(
            catchError((error: HttpErrorResponse) => this.handleError(error)),
            map((response: any) => response.message)
        )

    }

    getEvents() {
        let url = this.createUrl(environment.EVENTS.LIST_EVENTS);

        return this.http.get<Events>(url)
            .pipe(
                catchError((error: HttpErrorResponse) => this.handleError(error)),
                map((response: any) => response.body)
            );
    }

    getEventScores(eventId: string) {
        let url = this.createUrl(environment.EVENTS.GET_EVENT_SCORES);
        url = url.replace('{eventId}', eventId);

        return this.http.get<EventScores>(url)
            .pipe(
                catchError((error: HttpErrorResponse) => this.handleError(error)),
                map((response: any) => response.body)
            );
    }

    addEvent(body: AddEvent) {
        const url = this.createUrl(environment.EVENTS.ADD_EVENT);

        return this.http.post<string>(url, body)
            .pipe(
                catchError((error: HttpErrorResponse) => this.handleError(error)),
                map((response: any) => response.message)
            );
    }

    addTeam(body: AddTeam, eventId: string) {
        let url = this.createUrl(environment.EVENTS.ADD_TEAM);
        url = url.replace('{eventId}', eventId);

        return this.http.post<string>(url, body)
            .pipe(
                catchError((error: HttpErrorResponse) => this.handleError(error)),
                map((response: any) => response.message)
            );
    }

    addActivity(body: AddActivity, eventId: string) {
        let url = this.createUrl(environment.EVENTS.ADD_ACTIVITY);
        url = url.replace('{eventId}', eventId);

        return this.http.post<string>(url, body)
            .pipe(
                catchError((error: HttpErrorResponse) => this.handleError(error)),
                map((response: any) => response.message)
            );
    }

    addScore(body: AddScore, eventId: string, teamId: string, activityId: string) {
        let url = this.createUrl(environment.EVENTS.ADD_SCORE);
        url = url.replace('{eventId}', eventId);
        url = url.replace('{teamId}', teamId);
        url = url.replace('{activityId}', activityId);

        return this.http.put<string>(url, body)
        .pipe(
            catchError((error: HttpErrorResponse) => this.handleError(error)),
            map((response: any) => response.message)
        );
    }
}
