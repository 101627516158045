import { formatDate } from "@angular/common";

export function formatDateToString(date: any) {
    const unformattedDate = new Date(date);
    const yyyy = unformattedDate.getFullYear();
    let mm: any = unformattedDate.getMonth() + 1;
    let dd: any = unformattedDate.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    return dd + '/' + mm + '/' + yyyy;
}

export function getNativeDate(dateString: string) {
    if (dateString) {
        let dateParts = dateString.split("/");
        const date = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]);

        return formatDate(date, "yyyy-MM-dd", "en");
    }
    return "";
}