import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent {
  @Input() message: string = '';
  @Input() messageType: 'success' | 'error' = 'success';
  @Output() closed = new EventEmitter<void>();

  closeMessage() {
    this.closed.emit();
  }
}
